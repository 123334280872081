import React from 'react';
import {observe} from 'mobx';
import share_screen_scope from '../../webconnect/shareScreen.js';
import withDisplayCache from './DisplayCache.js';
import TransformVMLink from './TransformVMLink.js';
import {active_perm, active_vm_frame} from '../../activeVM.js';
import {listen_iframe_close} from './autoclose.js';
import '../../webconnect/altTab.js';
import '../../webconnect/clearAuthStorage.js';
import '../../webconnect/resize.js';
import { openZendeskChat } from "../../zendesk";


export class BareFrame extends React.Component {

  componentDidMount() {

      this.iframe.onload = async () => {


          while(this.iframe && !this.iframe.contentDocument) {
              await new Promise(r => setTimeout(r, 50));
          }

          // closed before initialization
          if(!this.iframe) return;


          this.bind_iframe_events();


          if(window.isElectron) {
            this.iframe.contentWindow.isDomainChanged = true;
            this.iframe.contentWindow.print_pdf = url => window.api.print(url);
          }


          // Bind activation and de-activation to visibility changes.

          this.observe_disposer = observe(active_perm, this.active_perm_onchange.bind(this));


          // Activate this frame if it is active.

          if(active_perm.get() === this.props.perm_id) {

              active_vm_frame.set(this.iframe);
              this.make_active();

          }


          share_screen_scope(this.iframe);

          listen_iframe_close(this.iframe, this.props.perm_id);

      };

  }


  active_perm_onchange({newValue, oldValue}) {

      if(newValue === this.props.perm_id) {

          active_vm_frame.set(this.iframe);
          this.make_active();

      }

      if(oldValue === this.props.perm_id) {

         this.make_inactive();

      }


      if(!newValue && active_vm_frame.get()) {

          active_vm_frame.set();

      }

  }


  componentWillUnmount() {

      this.observe_disposer && this.observe_disposer();

  }


  bind_iframe_events() {


      // Dynamic events like key presses and shortcut overrides are bound to methods called
      // `this.make_active` and `this.make_inactive`. They need to be managed to match the vm
      // visibility.

      // Static mouse events are "set and forget" since they rely on mouseovers and clicks.


      const pipe_event_to_iframe = e => {

          e.preventDefault();
          e.stopPropagation();

          this.iframe.contentDocument && this.iframe.contentDocument.dispatchEvent(new e.constructor(e.type, e));

          return false;

      };


      const evts = ['keydown', 'keyup', 'keypressed'];

      const bind_pipes = () => evts.forEach(evt => document.addEventListener(evt, pipe_event_to_iframe, true));
      const unbind_pipes = () => evts.forEach(evt => document.removeEventListener(evt, pipe_event_to_iframe, true));



      // this can be refactored with removableEventListener


      // Can't disable outright but I can get it to pop up an error message.

      const disable_shortcuts_f = e => {

          e.preventDefault();

          e.returnValue = '';

      };


      const disable_shortcuts = () => window.addEventListener('beforeunload', disable_shortcuts_f, true);
      const enable_shortcuts = () => window.removeEventListener('beforeunload', disable_shortcuts_f, true);


      this.make_active = () => {

          bind_pipes();
          disable_shortcuts();

      };

      this.make_inactive = () => {

          unbind_pipes();
          enable_shortcuts();

      };



      this.iframe.contentDocument.body.addEventListener('mouseover', this.make_active);
      this.iframe.contentDocument.body.addEventListener('click', this.make_active);

      this.iframe.contentDocument.body.addEventListener('mouseleave', this.make_inactive);
      document.body.addEventListener('click', this.make_inactive);


      // Mousemove and mouseup in the VM window activates the same events in the parent window

      this.iframe.contentWindow.addEventListener('mousemove', e => {

          window.document.dispatchEvent(new e.constructor(e.type, e));

      }, true);

      this.iframe.contentWindow.addEventListener('mouseup', e => {

          window.document.dispatchEvent(new e.constructor(e.type, e));

      }, true);



      // Hide the sidebar when we click in the iframe

      this.iframe.contentDocument.body.addEventListener('click', () => {
          window.$('body').removeClass('menu-open')

          if(window.isZendeskOpen){
            openZendeskChat();
              setTimeout(() => {
                window.$('body .the-main-content').focus();
                }, 500);
          }
      });
  }



  // should be removed. test later

  shouldComponentUpdate(nextProps, nextState) {

      return false;

  }


  render() {

      return <iframe title='WebConnect'
      {...this.props} className='webconnect-frame' 
      ref={r => {this.iframe = r;}} 
      allow="clipboard-write; clipboard-read; geolocation; microphone"></iframe>

  }

}


const {component, cache: cache_} =

    // Keeps records of components with the "vm_user_string" property
    withDisplayCache(

      // Fetches the API webconnect link and writes the "src" property
      TransformVMLink(

        BareFrame

      )

    );



export const WebconnectFrame = component;
export const vm_cache = cache_;
