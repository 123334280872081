import axios from 'axios';
import {observable, observe, toJS} from 'mobx';
import {memoize} from 'lodash';
import * as auth from './auth.js';
import * as zendesk from './zendesk.js';
import {show_loading_screen} from './loading.js';
import {is_whitelabel} from './consts.js';
import {whitelabel_info} from './whitelabel.js';
import { open_vm } from "./useRDP.js";
import {initializePendo} from "./pendo";


const listen_login = ({log_in, log_out}) => observe(auth.logged_in, () =>

  auth.logged_in.get() ? log_in() : log_out()

);


export const email = observable.box('');
export const full_name = observable.box('');
export const is_managed = observable.box();
export const user_id = observable.box();
export const is_ad_user = observable.box();
export const ad_user_id = observable.box();
export const user_whitelabel_status = observable.box();
export const show_chat = observable.box(false);


listen_login({

    log_in: show_loading_screen(async () => {

        const obj = await axios({url: '/api/users/'});
        
        full_name.set(obj.data.results[0].profile.full_name);
        email.set(obj.data.results[0].email);
        is_managed.set(obj.data.results[0].profile.managed_by !== null); // true for debug
        user_id.set(obj.data.results[0].id);
        is_ad_user.set(!!obj.data.results[0].ad_user);
        user_whitelabel_status.set(obj.data.results[0].profile.denver_stage === 4)
        show_chat.set(obj.data.results[0].profile.show_chat);

        initializePendo(obj.data.results[0].id);

        if(obj.data.results[0].ad_user) {
          ad_user_id.set(obj.data.results[0].ad_user.id);
        }

        if(is_whitelabel) {

            const info = obj.data.results[0].whitelabel_info;

            // Strip trailing slash to be consistent with const.web_api_entrypoint
            // See auth.js:99
            if(info?.endpoint?.endsWith('/')) {
              info.endpoint = info.endpoint.slice(0, -1);
            }

          whitelabel_info.set(info);
        }

        if(is_whitelabel || user_whitelabel_status.get()){
            const titleElement = document.querySelector('title');
            const imageElement = document.querySelector('#thumbnail');
            titleElement.textContent = 'Cloud Workstations Webconnect';
            imageElement.setAttribute('href', 'images/icon-whitelabel.png');
        }

        if(show_chat.get()){
            zendesk.initializeZendesk(obj.data.results[0].zendesk_chat_token)
        }
        
        setTimeout(() => show_loading_screen(load_permissions_and_vms)(), 0);
    }),

    log_out: () => {

        full_name.set('');
        email.set('');
        zendesk.cleanupZendesk();
        is_managed.set();
        user_id.set();
        is_ad_user.set();
        ad_user_id.set();
        user_whitelabel_status.set();
        show_chat.set(false);

        discard_data();

    }

});


export const user_list = observable.box({});

// window.user_list = () => toJS(user_list.get());

// {
//   178: { .. },
//   199: { .. }
// }

const set_user_list = obj => {

  // Refresh identity to force reassignment of the main user_list observable.

  user_list.set(Object.assign({}, obj));

};


export const vms_list = observable.box({});

// {
//    200: { ... }
//    203: { ... }
// }

export const vms_list_original = observable.box({});


export const load_vms = async () => {

    const vms = await axios({url: `/api/vms`});
    
    vms_list_original.set(vms.data.results);

}

export const load_permissions_and_vms = () =>
  Promise.all([load_permissions(), load_vms()])

export const load_permissions = async () => {

  if(is_ad_user.get()) return await load_ad_user_permissions();


  window.permission_list = () => toJS(permission_list.get());


  const vm_out = {};
  const perm_out = permission_list.get();

  const permissions = await axios({url: `/api/permissions/?vmuser_user=${user_id.get()}&user=${user_id.get()}&all=true`});

  permissions.data.forEach(perm => {

    // Some high-level transformations to make the vmpool interface consistent with the vm interface

    if(perm.app && perm.vmpool) {
      perm.type = 'vmpool-app';
    }

    if(perm.vmpool) {

      // todo: 
      perm.vm = {
        id: perm.vmpool.id,
        descriptor: perm.vmpool.name,
        is_vmpool: true,
        state: 'running'
      };

      perm.vmpool = null;

    }

    // end

    perm_out[perm.id] = perm;

    vm_out[perm.vm.id] = perm.vm;

  });

  permission_list.set(perm_out);
  vms_list.set(vm_out);

  if(is_managed.get() && Object.entries(permission_list.get()).length === 1) {
    open_vm(Object.keys(permission_list.get())[0]);
  }

};


const load_ad_user_permissions = async () => {

  const accesses = (await axios({url: `/api/adusers/${ad_user_id.get()}/accesses`})).data;

  const perm_out = {};
  const vm_out = {};


  accesses.forEach(access => {

    let id;

    // mimic the permission interface
    if(access.app) {
      id = access.app.id;

      access.type = access.vmpool ? 'vmpool-app' : "app";
    } else {
      id = access.id;

      access.type = access.vmpool ? 'vmpool' : "vm";
    }

    if(access.vmpool) {

      access.vm = {
        id: access.vmpool.id,
        descriptor: access.vmpool.name,
        is_vmpool: true
      };

      access.vmpool = null;

    }

    // mimic the same vm interface as with non-ad users
    // that way filtering logic in splash.js is consistent
    access.user = {id: user_id.get()};

    // end

    perm_out[id] = access;

    vm_out[access.vm.id] = access.vm;

  });

  permission_list.set(perm_out);
  vms_list.set(vm_out);

};


export const discard_data = () => {

  vms_list.set({});
  user_list.set({});
  permission_list.set({});

  // eslint-disable-next-line
  fetch_user_permissions.cache = new memoize.Cache;

};


export const permission_list = observable.box({});

// and then you have the actual, specific permissions with the rdp info & webconnect links that aren't cached ANYWHERE.
// or there's a flag that lets you flush the cache and force another request.

// {
//   100: { .. }
//   'aduser_120': { .. }
// }


const set_permission_list = obj => {

  // Refresh identity to force reassignment of the main permission_list observable.

  permission_list.set(Object.assign({}, obj));

};


export const fetch_permission = async perm_id => {

  try {

    const obj = await axios({
      url: `/api/permissions/${perm_id}?all=true`,
    });

    const _permission_list = permission_list.get();


    _permission_list[perm_id] = obj.data;

    set_permission_list(_permission_list);

    return _permission_list[perm_id];

  } catch(e) {

    console.error('bad permission lookup: ' + perm_id);

    return;

  }

};


// distinct from the above "fetch_permissions" as this applies also to vmusers that
// do not correspond to the currently logged-in user.

// this is used when clicking on a vmuser in the sidebar

export const fetch_user_permissions = memoize(async (vmuser_id, vm_id) => {

  const user_id = user_list.get()[vm_id].find(({id}) => id === vmuser_id).user.id;

  const vms = await axios({
    url: `/api/permissions/?vmuser=${vmuser_id}&user=${user_id}&all=true`
  });

  const permissions = vms.data.filter(({type}) => type !== 'vmpool');


  const _permission_list = permission_list.get();

  permissions.forEach(permission => {

    _permission_list[permission.id] = permission;

  });

  set_permission_list(_permission_list);


  return permissions;

});


export const fetch_vmusers = async vm_id => {

  const vmusers = (await axios({url: `/api/vmusers/?vm=${vm_id}&all=true`})).data;

  const _user_list = user_list.get();


  _user_list[vm_id] = [];

  vmusers.forEach(vmuser => {
    _user_list[vm_id].push(vmuser);
  });

  set_user_list(_user_list);

};
