import waitForElement from './util/waitForElement.js';

let isZendeskOpen = false
let isZendeskInitialized = false
window.isZendeskOpen = false

export const initializeZendesk = (zendesk_chat_token) => {
	if (isZendeskInitialized) return;

	waitForElement('#launcher', async function () {

		if (zendesk_chat_token) {
			window.zE("messenger", "loginUser", function (callback) {
				callback(zendesk_chat_token);
			});
		}

		isZendeskInitialized = true
	});
}

export const openZendeskChat = () => {
	window.zE('messenger', isZendeskOpen ? 'hide' : 'show');
	window.zE('messenger', isZendeskOpen ? 'close' : 'open');
	isZendeskOpen = !isZendeskOpen;
	window.isZendeskOpen = isZendeskOpen
}

export const cleanupZendesk = () => {
	window.$('#launcher').remove()
	isZendeskInitialized = false
}